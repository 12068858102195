@font-face {
  font-family: Inter;
  src: url(./Fonts/Inter.ttf);
}

/* ~~~~~~~~~~~~~~~~~~ Defining colors ~~~~~~~~~~~~~~~~~~ */
:root {
  --toggleBtn: linear-gradient(to right, hsl(230, 22%, 74%), hsl(230, 22%, 74%));
  --toggleBtnHover: linear-gradient(to right, hsl(210, 78%, 56%), hsl(146, 68%, 55%));
  --bg: hsl(0, 0%, 100%);
  --topBg: hsl(225, 100%, 98%);
  --cardBg: hsl(227, 47%, 96%);
  --cardBgHover: #e1e3f0;
  --smallTxt: hsl(228, 12%, 44%);
  --largeTxt: hsl(230, 17%, 14%);
  --overviewTxt: hsl(228, 12%, 44%);

  --LimeGreen: hsl(163, 72%, 41%);
  --BrightRed: hsl(356, 69%, 56%);
  
  --Facebook: hsl(208, 92%, 53%);
  --Twitter: hsl(203, 89%, 53%);
  --Instagram: linear-gradient(to right, hsl(37, 97%, 70%), hsl(329, 70%, 58%));
  --YouTube: hsl(348, 97%, 39%);
}
/* ~~~~~~~~~~~~~~~~~~ Dark Theme ~~~~~~~~~~~~~~~~~~ */
[data-theme='dark']{
  --toggleBtn: linear-gradient(to right, hsl(210, 78%, 56%), hsl(146, 68%, 55%));
  --toggleBtnHover: linear-gradient(to right, hsl(210, 78%, 56%), hsl(146, 68%, 55%));
  --bg: hsl(230, 17%, 14%);
  --topBg: hsl(232, 19%, 15%);
  --cardBg: hsl(228, 28%, 20%);
  --cardBgHover: #333a56;
  --smallTxt: hsl(228, 34%, 66%);
  --largeTxt: hsl(0, 0%, 100%);
  --overviewTxt: hsl(0, 0%, 100%);

  --LimeGreen: hsl(163, 72%, 41%);
  --BrightRed: hsl(356, 69%, 56%);
  
  --Facebook: hsl(208, 92%, 53%);
  --Twitter: hsl(203, 89%, 53%);
  --Instagram: linear-gradient(to right, hsl(37, 97%, 70%), hsl(329, 70%, 58%));
  --YouTube: hsl(348, 97%, 39%);
}

.background{
  /* background-color: var(--bg); */
  font-family: Inter;
}

.background-overlay-top{
  background-color: var(--topBg);
  width: 100%;
  height: 235px;
  position: aboslute;
  border-radius: 0 0 30px 30px;
  transition: 0.5s ease-in-out;
}

/* ~~~~~~~~~~~~~~~~~~ Header ~~~~~~~~~~~~~~~~~~ */
.site-title{
  font-size: 25px;
  line-height: 30px;
  font-weight: 700;
  color: var(--largeTxt);
  transition: 0.5s ease-in-out;
}

.total-followers{
  font-size: 14px;
  font-weight: 700;
  color: var(--smallTxt) !important;
  transition: 0.5s ease-in-out;
}

.toggle-area{
  display: flex;
  justify-content: end;
  align-items: center;
}

.hr-style{
  border-width: 0 0 0 0;
  border-color: var(--smallTxt) !important;
  transition: 0.5s ease-in-out;
}

@media screen and (max-width: 575px){
  .toggle-area{
    justify-content: space-between;
    margin: 0 0 40px 0;
  }

  .hr-style{
    border-width: 1px 0 0 0;
    border-style: solid;
    margin: 25px 0 14px 0;
  }
}

/* ~~~~~~~~~~~~~~~~~~ Card Stylings ~~~~~~~~~~~~~~~~~~ */
.card-properties{
  background-color: var(--cardBg);
  border-radius: 4px;
  border-width: 0;
  border-style: solid;
  /* margin: 0 12px 0 12px; */
  height: 212px;
  transition: 0.5s ease-in-out;
}
  .card-properties:hover{
    cursor: pointer;
    background-color: var(--cardBgHover);
    transition: 0.1s ease-in-out;
  }

.card-properties-border{
  background-color: var(--cardBg);
  border-radius: 0 0 4px 4px;
  border-width: 0;
  border-style: solid;
  /* margin: 0 12px 0 12px; */
  height: 212px;
  transition: 0.5s ease-in-out;
}

.card-properties-insta:hover{
  cursor: pointer;
  background-color: var(--cardBgHover);
  transition: 0.1s ease-in-out;
}

.card-border{
  border-radius: 4px 4px 0 0;
  border-width: 0;
  height: 4px;
  width: 100%;
}

.fb{
  background-color: var(--Facebook);
  transition: 0.5s ease-in-out;
}
.twit{
  background-color: var(--Twitter);
  transition: 0.5s ease-in-out;
}
.insta{
  background-image: var(--Instagram);
  transition: 0.5s ease-in-out;
}
.yt{
  background-color: var(--YouTube);
  transition: 0.5s ease-in-out;
}

.followers-big-num{
  color: var(--largeTxt);
  font-size: 52px;
  font-weight: 700;
  line-height: 52px;
  transition: 0.5s ease-in-out;
}

.followers-txt{
  color: var(--smallTxt);
  font-size: 13px;
  line-height: 13px;
  font-weight: 400;
  letter-spacing: 4px;
  transition: 0.5s ease-in-out;
}

.followers-change{
  font-size: 13px;
  font-weight: 700;
}

.increase{
  color: var(--LimeGreen);
  transition: 0.5s ease-in-out;
}

.decrease{
  color: var(--BrightRed);
  transition: 0.5s ease-in-out;
}

/* ~~~~~~~~~~~~~~~~~~ Overview Section Styling ~~~~~~~~~~~~~~~~~~ */
.overview-today{
  color: var(--overviewTxt);
  font-size: 23px;
  font-weight: 700;
  padding-top: 50px;
  transition: 0.5s ease-in-out;
}

.overview-cols{
  height: 126px;
}

.overview-cards{
  background-color: var(--cardBg);
  border-radius: 4px;
  height: 100%;
  padding: 25px 30px 26px 25px;
  transition: 0.5s ease-in-out;
}
  .overview-cards:hover{
    background-color: var(--cardBgHover);
    cursor: pointer;
    transition: 0.1s ease-in-out;
  }

.overview-card-title{
  font-size: 13px;
  font-weight: 700;
  color: var(--smallTxt);
  transition: 0.5s ease-in-out;
}

.overview-num{
  color: var(--largeTxt);
  font-size: 30px;
  font-weight: 700;
  line-height: 26px;
  transition: 0.5s ease-in-out;
}

.percent{
  line-height: 13px;
}

.marginBottom{
  padding-bottom: 45px;
}

/* ~~~~~~~~~~~~~~~~~~ Toggle Switch (W3 Schools) ~~~~~~~~~~~~~~~~~~ */
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 26px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 1px;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: var(--toggleBtn);
  -webkit-transition: .4s;
  transition: .4s;
  transition: 0.5s ease-in-out;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 3px;
  background-color: var(--topBg);
  -webkit-transition: .4s;
  transition: .4s;
  transition: 0.5s ease-in-out;
}

input:hover + .slider {
  background-image: var(--toggleBtnHover);
  transition: 0.1s ease-in-out;
}

input:checked + .slider {
  background-image: var(--toggleBtn);
  transition: 0.5s ease-in-out;
}

input:focus + .slider {
  background-image: var(--toggleBtn);
  transition: 0.5s ease-in-out;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}